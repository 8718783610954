import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Latest Notable Projects`}</h2>
    <ProjectCard title="Moonpay" link="https://www.moonpay.com/" bg="linear-gradient(to right, #FAE4E7 0%, #DBEEF1 100%)" mdxType="ProjectCard">
  Senior full stack developer @ Moonpay
    </ProjectCard>
    <ProjectCard title="Appre.Me" link="https://appre.me/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  CTO & Co-founder of AppreMe.
    </ProjectCard>
    <ProjectCard title="Factmata" link="https://factmata.com/" bg="linear-gradient(to right, #1D9AF2 0%, #EF2569 100%)" mdxType="ProjectCard">
  Helping to fight fake news and hate speach as an interim CTO.
    </ProjectCard>
    <ProjectCard title="Django Better Admin ArrayField" link="https://github.com/gradam/django-better-admin-arrayfield" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Small OSS utility for django admin
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      