import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The code is just a tool, but still, you have to know how to use it.`}</p>
    </blockquote>
    <p>{`I help people (and sometimes myself) make ideas come true. I've been doing it for over half a decade now! Technologies such as `}<a target="_blank" rel="noopener noreferrer" href="https://python.org">{`Python`}</a>{`, `}<a target="_blank" rel="noopener noreferrer" href="https://reactjs.org/">{`React`}</a>{` or `}<a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/">{`AWS`}</a>{` were very helpful along the way.`}</p>
    <p>{`I am passionate about entrepreneurship, startups, new technologies and sharing knowledge. I am a mentor for several aspiring programmers and ex-mentor for some that have become mentors themselves.`}</p>
    <p>{`In my career, I worked with clients from all around the world, mostly in small, self-organized teams or as a team leader. I've worked as a freelancer, Co-founder and employee both for product companies and software development companies.`}</p>
    <p>{`If you think we could benefit from each other, please do not hesitate to `}<a parentName="p" {...{
        "href": "mailto:kuba.semik@gmail.com"
      }}>{`contact me`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      