import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say Hi at `}<a parentName="p" {...{
        "href": "mailto:kuba.semik@gmail.com"
      }}>{`kuba.semik@gmail.com`}</a>{` or find me on other platforms:`}</p>
    <ul>
      <li parentName="ul">
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jakub-semik-a4981012a">LinkedIn</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/gradam">GitHub</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Jakub_Semik">Twitter</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@Jakub_Semik">Medium</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noopener noreferrer" href="https://dev.to/gradam">dev.to</a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      